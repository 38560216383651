.active{
    /* background-color: #062539; */
    background-color: #ffff;
    text-decoration: none;
    color: #FF7e00; 
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    font-size: large;


}
.inactive{
    text-decoration: none;
    color: black; 
   font-family: 'Mulish', sans-serif;
   font-weight: bold; 
   font-size: large;
}

a{
  text-decoration: none;
  color: #FF7e00;
}

a:hover {
  /* color: black; */
  color: #FF7e00; 

   /* opacity: 0.2;  */

}
