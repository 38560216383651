
.questionContainer{
    background: rgb(145, 81, 81);
}

.questionInput{
    box-shadow: none!important;
    border: none!important;
    background: rgb(145, 81, 81);
}
.backButton{
    box-shadow: none!important;
    border: none!important;
    /* cursor: pointer; */
}