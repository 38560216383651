.printButton{
    background-color: rgb(228, 217, 217) !important;
    box-shadow: none !important;

    
}
.deleteButton{
    background-color: rgb(228, 217, 217) !important;
    /* box-shadow: none !important; */
    
}

.errorBorder{
    border-color: red!important;
}
.errorText{
    color: red!important;
}
